
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "payment-plan-form",
  emit: ["update-success"],
  components: {},
  props: {
    //mutual: { type: Object, default: {} as Mutual }
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const loadingProductLines = ref<boolean>(false);
    const loadingDocTypes = ref<boolean>(false);
    const formData = ref({
      name: "",
      description: "",
      max_quotas: 0,
      interest_rate: 0,
      minimal_price: 0,
      active: false,
      payment_plan: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 100,
          message: "Este campo admite máximo 100 caracteres",
          trigger: "change",
        },
      ],
      description: [
        {
          max: 255,
          message: "Este campo admite máximo 255 caracteres",
          trigger: "change",
        },
      ],
      max_quotas: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
      interest_rate: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
      minimal_price: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
      active: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
    });

    onMounted(() => {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Planes", [
        "Planes de pago",
        isEditPage() ? "Editar" : "Nuevo",
      ]);
      if (isEditPage()) {
        loadPlan();
      }
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (isEditPage()) {
            formData.value.payment_plan = String(route.params.uuid);
          }
          store
            .dispatch(
              isEditPage()
                ? Actions.PAYMENT_PLANS_UPDATE
                : Actions.PAYMENT_PLANS_CREATE,
              formData.value
            )
            .then(() => {
              loading.value = false;
              if (store.state.PaymentPlansModule.success) {
                emit("update-success");
                ToastService.show(
                  "Plan " +
                    (isEditPage() ? "actualizado" : "creado") +
                    " con éxito",
                  "success"
                );
                router.push({
                  name: "mutual_paymentplans",
                  //params: { uuid: store.state.ProfilingModule.request.uuid },
                });
              }
            })
            .catch(() => {
              displayErrors(store, "PaymentPlansModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    const loadPlan = () => {
      loading.value = true;
      store
        .dispatch(Actions.PAYMENT_PLANS_GET, route.params.uuid)
        .then(() => {
          loading.value = false;
          formData.value = store.state.PaymentPlansModule.plan;
        })
        .catch(() => {
          displayErrors(store, "PaymentPlansModule");
          loading.value = false;
        });
    };

    const isEditPage = () => {
      return typeof route.params.uuid !== "undefined";
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      loadingProductLines,
      loadingDocTypes,
    };
  },
});
